export const leagueApi = api => ({
  create(
    name,
    nameEn,
    logo,
    weekName,
    weekNameEn,
    desktopCover,
    mobileCover,
    slug,
    slugEn,
    order
  ) {
    return api.post('/league/create', {
      name,
      nameEn,
      logo,
      weekName,
      weekNameEn,
      desktopCover,
      mobileCover,
      slug,
      slugEn,
      order
    });
  },
  list(page) {
    return api.get(`/league/list/${page}`);
  },
  get(id) {
    return api.get(`/league/${id}`);
  },
  update(
    name,
    nameEn,
    logo,
    weekName,
    weekNameEn,
    desktopCover,
    mobileCover,
    slug,
    slugEn,
    order,
    id
  ) {
    return api.post(`/league/update/${id}`, {
      name,
      nameEn,
      logo,
      weekName,
      weekNameEn,
      desktopCover,
      mobileCover,
      slug,
      slugEn,
      order
    });
  },
  delete(id) {
    return api.delete(`/league/delete/${id}`);
  },
  allList() {
    return api.get('/league/list/${page}');
  }
});
