export const convertToSlug = str => {
  const trMap = {
    çÇ: 'c',
    ğĞ: 'g',
    şŞ: 's',
    üÜ: 'u',
    ıİ: 'i',
    öÖ: 'o'
  };
  for (let key in trMap) {
    str = str.replace(new RegExp(`[${key}]`, 'g'), trMap[key]);
  }
  return str
    .replace(/[^-a-zA-Z0-9\s]+/gi, '')
    .replace(/\s/gi, '-')
    .replace(/[-]+/gi, '-')
    .toLowerCase();
};
