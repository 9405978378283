export const paymentApi = api => ({
  create(name, logo) {
    return api.post('/payment/create', {
      name,
      logo
    });
  },
  list(page) {
    return api.get(`/payment/list/${page}`);
  },
  get(id) {
    return api.get(`/payment/${id}`);
  },
  update(name, logo, id) {
    return api.post(`/payment/update/${id}`, {
      name,
      logo
    });
  },
  delete(id) {
    return api.delete(`/payment/delete/${id}`);
  }
});
