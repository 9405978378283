export const matchApi = api => ({
  create(
    league,
    homeTeam,
    awayTeam,
    name,
    nameEn,
    matchDate,
    homeContent,
    homeContentEn,
    awayContent,
    awayContentEn,
    homeHighlight,
    homeHighlightEn,
    awayHighlight,
    awayHighlightEn,
    slug,
    slugEn,
    isShowHome,
    title,
    titleEn,
    description,
    descriptionEn,
    cover,
    homeScore,
    awayScore,
    isShowSlider,
    isFeaturedMatch,
    homeOrder,
    sliderOrder,
    featuredMatchOrder,
    site,
    siteContent,
    siteContentEn
  ) {
    return api.post('/match/create', {
      league,
      homeTeam,
      awayTeam,
      name,
      nameEn,
      matchDate,
      homeContent,
      homeContentEn,
      awayContent,
      awayContentEn,
      homeHighlight,
      homeHighlightEn,
      awayHighlight,
      awayHighlightEn,
      slug,
      slugEn,
      isShowHome,
      title,
      titleEn,
      description,
      descriptionEn,
      cover,
      homeScore,
      awayScore,
      isShowSlider,
      isFeaturedMatch,
      homeOrder,
      sliderOrder,
      featuredMatchOrder,
      site,
      siteContent,
      siteContentEn
    });
  },
  list(page) {
    return api.get(`/match/list/${page}`);
  },
  archive(page) {
    return api.get(`/match/archive/${page}`);
  },
  get(id) {
    return api.get(`/match/${id}`);
  },
  update(
    league,
    homeTeam,
    awayTeam,
    name,
    nameEn,
    matchDate,
    homeContent,
    homeContentEn,
    awayContent,
    awayContentEn,
    homeHighlight,
    homeHighlightEn,
    awayHighlight,
    awayHighlightEn,
    slug,
    slugEn,
    isShowHome,
    title,
    titleEn,
    description,
    descriptionEn,
    cover,
    homeScore,
    awayScore,
    isShowSlider,
    isFeaturedMatch,
    homeOrder,
    sliderOrder,
    featuredMatchOrder,
    site,
    siteContent,
    siteContentEn,
    id
  ) {
    return api.post(`/match/update/${id}`, {
      league,
      homeTeam,
      awayTeam,
      name,
      nameEn,
      matchDate,
      homeContent,
      homeContentEn,
      awayContent,
      awayContentEn,
      homeHighlight,
      homeHighlightEn,
      awayHighlight,
      awayHighlightEn,
      slug,
      slugEn,
      isShowHome,
      title,
      titleEn,
      description,
      descriptionEn,
      cover,
      homeScore,
      awayScore,
      isShowSlider,
      isFeaturedMatch,
      homeOrder,
      sliderOrder,
      featuredMatchOrder,
      site,
      siteContent,
      siteContentEn
    });
  },
  delete(id) {
    return api.delete(`/match/delete/${id}`);
  },
  changeArchive(id, archived) {
    return api.post(`/match/change-archive/${id}`, {
      archived
    });
  }
});
