import React from 'react';
import { FormErrorMessage } from 'components';

const AppCheckbox = ({
  label,
  name,
  placeholder,
  register,
  error,
  rules,
  isFullWidth = true,
  ...props
}) => {
  return (
    <div className={`mb-5 ${isFullWidth ? 'w-full' : 'mr-4'}`}>
      <div className="flex items-center px-4 rounded-lg border border-gray-300">
        <input
          type="checkbox"
          id={name}
          className={`w-4 h-4 text-yellow-600 bg-gray-100 rounded border-gray-300 focus:ring-yellow-500 focus:border-yellow-500 block p-2.5 ${
            error
              ? 'border-red-500 focus:border-red-500'
              : 'border-gray-300 focus:border-gray-700'
          }`}
          placeholder={placeholder}
          {...register(name, rules)}
          {...props}
        />
        <label
          htmlFor={name}
          className={`w-full py-4 ml-2 block text-sm ${
            error ? 'text-red-600' : 'text-gray-600'
          }`}
        >
          {label}
        </label>
      </div>
      <FormErrorMessage label={label} error={error} />
    </div>
  );
};

export default AppCheckbox;
