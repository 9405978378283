export const categoryApi = api => ({
  create(name, slug) {
    return api.post('/category/create', {
      name,
      slug
    });
  },
  list(page) {
    return api.get(`/category/list/${page}`);
  },
  get(id) {
    return api.get(`/category/${id}`);
  },
  update(name, slug, id) {
    return api.post(`/category/update/${id}`, {
      name,
      slug
    });
  },
  delete(id) {
    return api.delete(`/category/delete/${id}`);
  }
});
