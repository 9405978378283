export const slideApi = api => ({
  create(title, description, link, image) {
    return api.post('/slide/create', {
      title,
      description,
      link,
      image
    });
  },
  list(page) {
    return api.get(`/slide/list/${page}`);
  },
  get(id) {
    return api.get(`/slide/${id}`);
  },
  update(title, description, link, image, id) {
    return api.post(`/slide/update/${id}`, {
      title,
      description,
      link,
      image
    });
  },
  delete(id) {
    return api.delete(`/slide/delete/${id}`);
  }
});
