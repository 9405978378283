export const siteAPi = api => ({
  create(
    name,
    slug,
    logo,
    rated,
    ratedCount,
    claimedCount,
    bonus,
    bonusEn,
    terms,
    termsEn,
    link,
    payments,
    averagePayout,
    currencies,
    advantage_1_title,
    advantage_1_titleEn,
    advantage_1_status,
    advantage_2_title,
    advantage_2_titleEn,
    advantage_2_status,
    advantage_3_title,
    advantage_3_titleEn,
    advantage_3_status,
    advantage_4_title,
    advantage_4_titleEn,
    advantage_4_status,
    advantage_5_title,
    advantage_5_titleEn,
    advantage_5_status,
    advantage_6_title,
    advantage_6_titleEn,
    advantage_6_status
  ) {
    return api.post('/site/create', {
      name,
      slug,
      logo,
      rated,
      ratedCount,
      claimedCount,
      bonus,
      bonusEn,
      terms,
      termsEn,
      link,
      payments,
      averagePayout,
      currencies,
      advantage_1_title,
      advantage_1_titleEn,
      advantage_1_status,
      advantage_2_title,
      advantage_2_titleEn,
      advantage_2_status,
      advantage_3_title,
      advantage_3_titleEn,
      advantage_3_status,
      advantage_4_title,
      advantage_4_titleEn,
      advantage_4_status,
      advantage_5_title,
      advantage_5_titleEn,
      advantage_5_status,
      advantage_6_title,
      advantage_6_titleEn,
      advantage_6_status
    });
  },
  list(page) {
    return api.get(`/site/list/${page}`);
  },
  get(id) {
    return api.get(`/site/${id}`);
  },
  update(
    name,
    slug,
    logo,
    rated,
    ratedCount,
    claimedCount,
    bonus,
    bonusEn,
    terms,
    termsEn,
    link,
    payments,
    averagePayout,
    currencies,
    advantage_1_title,
    advantage_1_titleEn,
    advantage_1_status,
    advantage_2_title,
    advantage_2_titleEn,
    advantage_2_status,
    advantage_3_title,
    advantage_3_titleEn,
    advantage_3_status,
    advantage_4_title,
    advantage_4_titleEn,
    advantage_4_status,
    advantage_5_title,
    advantage_5_titleEn,
    advantage_5_status,
    advantage_6_title,
    advantage_6_titleEn,
    advantage_6_status,
    id
  ) {
    return api.post(`/site/update/${id}`, {
      name,
      slug,
      logo,
      rated,
      ratedCount,
      claimedCount,
      bonus,
      bonusEn,
      terms,
      termsEn,
      link,
      payments,
      averagePayout,
      currencies,
      advantage_1_title,
      advantage_1_titleEn,
      advantage_1_status,
      advantage_2_title,
      advantage_2_titleEn,
      advantage_2_status,
      advantage_3_title,
      advantage_3_titleEn,
      advantage_3_status,
      advantage_4_title,
      advantage_4_titleEn,
      advantage_4_status,
      advantage_5_title,
      advantage_5_titleEn,
      advantage_5_status,
      advantage_6_title,
      advantage_6_titleEn,
      advantage_6_status
    });
  },
  delete(id) {
    return api.delete(`/site/delete/${id}`);
  }
});
